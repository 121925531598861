"use client";

import Image from "next/image";
import styles from "../../page.module.css";
import { useMediaQuery } from "react-responsive";
import {
  useState,
  useEffect,
  useRef,
  useContext,
  useLayoutEffect,
} from "react";
import Link from "next/link";
import axios from "axios";
import { PALETTE } from "@/app/_lib/constant/palette";
import serverURL from "@/app/_lib/constant/serverURL";
import { PageContext } from "../../providers";
import CryptoJS from "crypto-js";
//import { GoogleLogin, useGoogleLogin } from "@stack-pulse/next-google-login";
import { useRouter } from "next/navigation";
import { COUNTRY_LIST } from "@/app/_lib/constant/country-constant";
import { Translator } from "@/app/_lib/langugage/language";
// import GoogleLogin from "react-google-login";
import { GoogleLogin } from "@react-oauth/google";
import { GoogleOAuthProvider, useGoogleLogin } from "@react-oauth/google";

export default function Page({ searchParams }: { searchParams: any }) {
  const { user, country, setUser }: any = useContext(PageContext);
  const router = useRouter();
  if (user?.id !== -1) {
    // router.push("/");
  }

  const [isMobileChk, setIsMobileChk] = useState(false);
  const isMobile = useMediaQuery({
    query: "(max-width:767px)",
  });
  const isPc = useMediaQuery({
    query: "(min-width:1024px)",
  });

  const isTablet = useMediaQuery({
    query: "(min-width:768px) and (max-width:1023px)",
  });
  useEffect(() => {
    // mobile 쿼리로 인해 값이 바뀔 때 수행
    if (isMobile) setIsMobileChk(true);
    else setIsMobileChk(true);
  }, [isMobile]);

  // const [email, setEmail] = useState("");
  // const [password, setPassword] = useState("");

  // const webClientId: string ="372037994889-9kevche73gkf5lajhrkqs9g5si9m3pa4.apps.googleusercontent.com";

  const googleLogin: any = useGoogleLogin({
    onSuccess: async (tokenResponse: any) => {
      // window.alert(country);
      if (country === COUNTRY_LIST.한국) {
        let preUserChk = false;
        await axios
          .post(`${serverURL}/user/loginSnsPre`, {
            access_token_sns: tokenResponse?.access_token,
            sns: "google",
            country,
          })
          .then((res) => {
            if (res.data.status === "true" && res.data.user) {
              preUserChk = true;
              const accessToken: any = res.data.accessToken;
              const refreshToken: any = res.data.refreshToken;
              const password: any = res.data.alpha;

              const user: any = res.data.user;
              const bytes = CryptoJS.AES.decrypt(
                password,
                process.env.NEXT_PUBLIC_CRYPTO_SECRET as string
              );
              const alpha = bytes.toString(CryptoJS.enc.Utf8);
              window.localStorage.setItem("accessToken", accessToken);
              window.localStorage.setItem("refreshToken", refreshToken);
              window.localStorage.setItem("password", alpha);
              window.localStorage.setItem("sns", "google");
              window.localStorage.setItem("snsId", alpha);
              window.localStorage.setItem("email", user?.email);
              setUser(user);
              const lastLinkProfile =
                window.localStorage.getItem("lastLinkProfile");
              const lastLinkPictorial =
                window.localStorage.getItem("lastLinkPictorial");
              if (lastLinkProfile && lastLinkProfile !== "null") {
                window.localStorage.removeItem("lastLinkProfile");
                router.push(`/profile?link=${lastLinkProfile}`);
              } else if (lastLinkPictorial && lastLinkPictorial !== "null") {
                window.localStorage.removeItem("lastLinkPictorial");
                router.push(`/pictorial?id=${lastLinkPictorial}`);
              } else {
                router.push("/");
              }
            }
          });
        if (!preUserChk) {
          onClickCertification({
            access_token_sns: tokenResponse?.access_token,
            sns: "google",
          });
        }
      } else {
        await axios
          .post(`${serverURL}/user/loginSnsFo`, {
            access_token_sns: tokenResponse?.access_token,
            sns: "google",
            country,
          })
          .then((res) => {
            if (res.data.status === "true") {
              const accessToken: any = res.data.accessToken;
              const refreshToken: any = res.data.refreshToken;
              const password: any = res.data.alpha;

              const user: any = res.data.user;
              const bytes = CryptoJS.AES.decrypt(
                password,
                process.env.NEXT_PUBLIC_CRYPTO_SECRET as string
              );
              const alpha = bytes.toString(CryptoJS.enc.Utf8);
              window.localStorage.setItem("accessToken", accessToken);
              window.localStorage.setItem("refreshToken", refreshToken);
              window.localStorage.setItem("password", alpha);
              window.localStorage.setItem("sns", "google");
              window.localStorage.setItem("snsId", alpha);
              window.localStorage.setItem("email", user?.email);
              setUser(user);
              const lastLinkProfile =
                window.localStorage.getItem("lastLinkProfile");
              const lastLinkPictorial =
                window.localStorage.getItem("lastLinkPictorial");
              if (lastLinkProfile && lastLinkProfile !== "null") {
                window.localStorage.removeItem("lastLinkProfile");
                router.push(`/profile?link=${lastLinkProfile}`);
              } else if (lastLinkPictorial && lastLinkPictorial !== "null") {
                window.localStorage.removeItem("lastLinkPictorial");
                router.push(`/pictorial?id=${lastLinkPictorial}`);
              } else {
                router.push("/");
              }
            } else {
              window.alert(
                Translator("오류 발생. 다시 시도해주세요.", country)
              );
            }
          });
      }
    },
    ux_mode: "popup",
  });

  /*
   useGoogleLogin({
    uxMode: "popup",
    clientId: webClientId,
    onSuccess: async (tokenResponse: any) => {
      window.alert(country);
      if (country === COUNTRY_LIST.한국) {
        let preUserChk = false;
        await axios
          .post(`${serverURL}/user/loginSnsPre`, {
            access_token_sns: tokenResponse?.tokenObj?.id_token,
            sns: "google",
            country,
          })
          .then((res) => {
            if (res.data.status === "true") {
              const accessToken: any = res.data.accessToken;
              const refreshToken: any = res.data.refreshToken;
              const password: any = res.data.alpha;

              const user: any = res.data.user;
              const bytes = CryptoJS.AES.decrypt(
                password,
                process.env.NEXT_PUBLIC_CRYPTO_SECRET as string
              );
              const alpha = bytes.toString(CryptoJS.enc.Utf8);
              window.localStorage.setItem("accessToken", accessToken);
              window.localStorage.setItem("refreshToken", refreshToken);
              window.localStorage.setItem("password", alpha);
              window.localStorage.setItem("sns", "google");
              window.localStorage.setItem("snsId", alpha);
              window.localStorage.setItem("email", user?.email);
              setUser(user);
              const lastLinkProfile =
                window.localStorage.getItem("lastLinkProfile");
              const lastLinkPictorial =
                window.localStorage.getItem("lastLinkPictorial");
              if (lastLinkProfile) {
                window.localStorage.removeItem("lastLinkProfile");
                router.push(`/profile/${lastLinkProfile}`);
              } else if (lastLinkPictorial) {
                window.localStorage.removeItem("lastLinkPictorial");
                router.push(`/pictorial/${lastLinkPictorial}`);
                preUserChk = true;
              }
            }
          });
        if (!preUserChk) {
          onClickCertification({
            access_token_sns: tokenResponse?.tokenObj?.id_token,
            sns: "google",
          });
        }
      } else {
        await axios
          .post(`${serverURL}/user/loginSnsFo`, {
            access_token_sns: tokenResponse?.tokenObj?.id_token,
            sns: "google",
            country,
          })
          .then((res) => {
            if (res.data.status === "true") {
              const accessToken: any = res.data.accessToken;
              const refreshToken: any = res.data.refreshToken;
              const password: any = res.data.alpha;

              const user: any = res.data.user;
              const bytes = CryptoJS.AES.decrypt(
                password,
                process.env.NEXT_PUBLIC_CRYPTO_SECRET as string
              );
              const alpha = bytes.toString(CryptoJS.enc.Utf8);
              window.localStorage.setItem("accessToken", accessToken);
              window.localStorage.setItem("refreshToken", refreshToken);
              window.localStorage.setItem("password", alpha);
              window.localStorage.setItem("sns", "google");
              window.localStorage.setItem("snsId", alpha);
              window.localStorage.setItem("email", user?.email);
              setUser(user);
              const lastLinkProfile =
                window.localStorage.getItem("lastLinkProfile");
              const lastLinkPictorial =
                window.localStorage.getItem("lastLinkPictorial");
              if (lastLinkProfile) {
                window.localStorage.removeItem("lastLinkProfile");
                router.push(`/profile/${lastLinkProfile}`);
              } else if (lastLinkPictorial) {
                window.localStorage.removeItem("lastLinkPictorial");
                router.push(`/pictorial/${lastLinkPictorial}`);
              } else {
                router.push("/");
              }
            } else {
              window.alert(
                Translator("오류 발생. 다시 시도해주세요.", country)
              );
            }
          });
      }
    },
  });
  */
  useEffect(() => {
    const jquery = document.createElement("script");
    jquery.src = "https://code.jquery.com/jquery-1.12.4.min.js";
    const iamport = document.createElement("script");
    iamport.src = "https://cdn.iamport.kr/js/iamport.payment-1.2.0.js";
    document.head.appendChild(jquery);
    document.head.appendChild(iamport);
    return () => {
      document.head.removeChild(jquery);
      document.head.removeChild(iamport);
    };
  }, []);

  const onClickCertification = async ({
    access_token_sns,
    sns,
  }: {
    access_token_sns: any;
    sns: any;
  }) => {
    const { IMP }: any = window;
    IMP.init("imp54153407");

    IMP.certification(
      {
        merchant_uid: `mid_${new Date().getTime()}`,
        m_redirect_url: `${window.location.href}?&access_token_sns=${access_token_sns}&sns=${sns}&country=${country}`,
        //m_redirect_url : "{리디렉션 될 URL}", // 모바일환경에서 popup:false(기본값) 인 경우 필수, 예: https://www.myservice.com/payments/complete/mobile
        popup: false, // PC환경에서는 popup 파라메터가 무시되고 항상 true 로 적용됨
        //pg: 'danal.B010008210',
      },
      async function (rsp: any) {
        if (rsp.success) {
          await axios
            .post(`${serverURL}/user/loginSnsKo`, {
              access_token_sns,
              sns,
              country,
              imp_uid: rsp.imp_uid,
            })
            .then((res) => {
              if (res.data.status === "true") {
                const accessToken: any = res.data.accessToken;
                const refreshToken: any = res.data.refreshToken;
                const password: any = res.data.alpha;

                const user: any = res.data.user;
                const bytes = CryptoJS.AES.decrypt(
                  password,
                  process.env.NEXT_PUBLIC_CRYPTO_SECRET as string
                );
                const alpha = bytes.toString(CryptoJS.enc.Utf8);
                window.localStorage.setItem("accessToken", accessToken);
                window.localStorage.setItem("refreshToken", refreshToken);
                window.localStorage.setItem("password", alpha);
                window.localStorage.setItem("sns", "google");
                window.localStorage.setItem("snsId", alpha);
                window.localStorage.setItem("email", user?.email);
                setUser(user);
                const lastLinkProfile =
                  window.localStorage.getItem("lastLinkProfile");
                const lastLinkPictorial =
                  window.localStorage.getItem("lastLinkPictorial");
                if (lastLinkProfile && lastLinkProfile !== "null") {
                  window.localStorage.removeItem("lastLinkProfile");
                  router.push(`/profile?link=${lastLinkProfile}`);
                } else if (lastLinkPictorial && lastLinkPictorial !== "null") {
                  window.localStorage.removeItem("lastLinkPictorial");
                  router.push(`/pictorial?id=${lastLinkPictorial}`);
                } else {
                  router.push("/");
                }
              } else {
                window.alert(
                  Translator("오류 발생. 다시 시도해주세요.", country)
                );
              }
            });
        } else {
          // 인증 실패 시 로직,
          window.alert(Translator("실패 하셨습니다.", country));
        }
      }
    );
  };

  // const [certChk, setCertChk] = useState(false);
  useEffect(() => {
    async function rediectIMP() {
      // callback
      // return;
      if (window.location.href.split("?")?.length <= 1) return;
      const query =
        window.location.href.split("?")[
          window.location.href.split("?")?.length - 1
        ];

      // console.log(query.split("&"));
      const imp_uid = query.split("&")[4].split("=")[1]; // searchParams?.imp_uid;
      const success = query.split("&")[6].split("=")[1]; // searchParams?.success;
      const access_token_sns = query.split("&")[0].split("=")[1]; // searchParams?.access_token_sns;
      const sns = query.split("&")[1].split("=")[1]; // searchParams?.sns;
      const country = query.split("&")[2].split("=")[1]; // searchParams?.country;

      if (success === "true") {
        await axios
          .post(`${serverURL}/user/loginSnsKo`, {
            access_token_sns,
            sns,
            country,
            imp_uid: imp_uid,
          })
          .then((res) => {
            if (res.data.status === "true") {
              const accessToken: any = res.data.accessToken;
              const refreshToken: any = res.data.refreshToken;
              const password: any = res.data.alpha;

              const user: any = res.data.user;
              const bytes = CryptoJS.AES.decrypt(
                password,
                process.env.NEXT_PUBLIC_CRYPTO_SECRET as string
              );
              const alpha = bytes.toString(CryptoJS.enc.Utf8);
              window.localStorage.setItem("accessToken", accessToken);
              window.localStorage.setItem("refreshToken", refreshToken);
              window.localStorage.setItem("password", alpha);
              window.localStorage.setItem("sns", "google");
              window.localStorage.setItem("snsId", alpha);
              window.localStorage.setItem("email", user?.email);
              setUser(user);
              const lastLinkProfile =
                window.localStorage.getItem("lastLinkProfile");
              const lastLinkPictorial =
                window.localStorage.getItem("lastLinkPictorial");
              if (lastLinkProfile && lastLinkProfile !== "null") {
                window.localStorage.removeItem("lastLinkProfile");
                router.push(`/profile?link=${lastLinkProfile}`);
              } else if (lastLinkPictorial && lastLinkPictorial !== "null") {
                window.localStorage.removeItem("lastLinkPictorial");
                router.push(`/pictorial?id=${lastLinkPictorial}`);
              } else {
                router.push("/");
              }
            } else {
              window.alert(
                Translator("오류 발생. 다시 시도해주세요.", country)
              );
            }
          });
      }
    }
    rediectIMP();
  }, []);

  return isMobileChk ? (
    <main className="AppBody">
      <div
        style={{
          width: "100vw",
          height: "100vh",
          backgroundColor: "rgba(0,0,0,0.3)",
          justifyContent: "center",
          alignContent: "center",
          alignItems: "center",
          // padding: 16,
          background: PALETTE.COLOR_BLACK,
        }}
      >
        <div
          style={{
            boxShadow: "10px 5px 5px 5px black",
            width: "100%",
            maxWidth: 1100,
            height: "100%",
            backgroundColor: PALETTE.COLOR_BLACK,
            justifyContent: "center",
            alignContent: "center",
            alignItems: "center",
            paddingTop: isPc ? 20 : 0,
            paddingBottom: isPc ? 20 : 0,
          }}
        >
          {/*
          <div
            style={{
              width: "100%",
              height: isMobile ? "20vh" : "20%",
              paddingTop: isPc ? 0 : 20,
              paddingRight: isPc ? 0 : 16,
              flexDirection: "row",
              alignContent: "center",
              alignItems: "center",
              justifyContent: "space-between",
              zIndex: 2,
              backgroundColor: isPc ? "none" : "rgba(0,0,0,0.55)",
            }}
          >
            <div
              style={{
                width: "30%",

                justifyContent: "center",
                alignContent: "center",
                alignItems: "center",
              }}
            >
              <img
                src="/assets/auth/adult.png"
                style={{
                  height: "100%",
                  maxHeight: isMobile ? 60 : 120,
                }}
              ></img>
            </div>
            <div
              style={{
                marginLeft: isPc ? 0 : 10,
                width: "70%",
                paddingRight: isMobile ? 0 : 16,
                justifyContent: "center",
                alignContent: "center",
                alignItems: "center",
              }}
            >
              <div
                style={{
                  width: "100%",
                  color: "white",
                  marginBottom: 20,
                  fontSize: isPc ? 17 : 14,
                }}
              >
                {Translator(
                  `이 정보 내용은 청소년 유해매체물로소 [정보통신망 이용 촉진 및 정보보호 등에 관환 법률] 및 [청소년 보호법] 의 규정에 의하여 19세 미만의 청소년이 이용할 수 없습니다.`,
                  country
                )}
              </div>
              <div
                style={{
                  height: isMobile ? 45 : 55,
                  borderRadius: 10,
                  color: "white",
                  fontWeight: "bold",
                  backgroundColor: PALETTE.COLOR_BUTTON,
                  justifyContent: "center",
                  alignContent: "center",
                  alignItems: "center",
                  width: "100%",
                  cursor: "pointer",
                }}
                onClick={() => {
                  window.location.href = "https://nmoment.live/app";
                }}
              >
                {Translator("19세 미만 나가기", country)}
              </div>
            </div>
          </div>
          */}
          <div
            style={{
              width: "100%",
              height: isMobile ? "100vh" : "100%",
              flexDirection: "row",
              alignContent: "center",
              alignItems: "center",
            }}
          >
            <div
              style={
                !isPc
                  ? {
                      width: "100%",
                      position: "absolute",
                      zIndex: 1,
                      height: "100%",
                      justifyContent: "center",
                      alignContent: "center",
                      alignItems: "center",
                    }
                  : {
                      width: "50%",
                      height: "100%",
                      justifyContent: "center",
                      alignContent: "center",
                      alignItems: "center",
                    }
              }
            >
              <img
                src="/assets/auth/loginBG.jpeg"
                style={{
                  width: "200%",
                  marginRight: "100%",
                  height: isPc ? "100%" : "100vh",
                  zIndex: 1,
                  objectFit: isPc ? "cover" : "cover",
                }}
              ></img>
            </div>
            <div
              style={{
                zIndex: 2,
                backgroundColor: "rgba(0,0,0,0.55)",
                borderLeft: isPc ? "1px solid #838383" : "none",
                paddingLeft: 16,
                paddingRight: 16,
                width: isPc ? "50%" : "100%",
                height: "100%",
                paddingBottom: 20,
              }}
            >
              <div
                style={{
                  width: "100%",
                  height: "100%",
                  justifyContent: "space-between",
                  alignContent: "center",
                  alignItems: "center",
                  // ju
                }}
              >
                <div
                  style={{
                    height: "20%",
                    justifyContent: "center",
                  }}
                >
                  <div
                    style={{
                      alignContent: "center",
                      alignItems: "center",
                      flexDirection: "row",
                      width: "100%",
                    }}
                  >
                    <img
                      src="/icon.png"
                      style={{
                        width: 45,
                        height: 45,
                        borderRadius: 100,
                      }}
                    ></img>
                    <div
                      style={{
                        color: "white",
                        fontWeight: "bold",
                        marginLeft: 30,
                        fontSize: isMobile ? 24 : 36,
                      }}
                    >
                      NPICK STUDIO
                    </div>
                  </div>
                </div>
                <div
                  style={{
                    height: "40%",
                    width: "100%",
                    paddingLeft: isPc ? 20 : 0,
                    paddingRight: isPc ? 20 : 0,
                    justifyContent: "center",
                    alignContent: "center",
                    alignItems: "center",
                  }}
                >
                  <div
                    onClick={() => {
                      let userAgentString = navigator.userAgent;
                      // Detect Chrome
                      let chromeAgent = userAgentString.indexOf("Chrome") > -1;
                      // Detect Internet Explorer
                      let IExplorerAgent =
                        userAgentString.indexOf("MSIE") > -1 ||
                        userAgentString.indexOf("rv:") > -1;
                      // Detect Safari
                      let safariAgent = userAgentString.indexOf("Safari") > -1;
                      // Discard Safari since it also matches Chrome
                      if (chromeAgent && safariAgent) safariAgent = false;
                      // Detect Opera
                      let operaAgent = userAgentString.indexOf("OP") > -1;
                      // Discard Chrome since it also matches Opera
                      if (chromeAgent && operaAgent) chromeAgent = false;

                      if (!chromeAgent && !safariAgent && !IExplorerAgent) {
                        //setGoogleAble(true);
                        window.alert(
                          Translator(
                            "구글 로그인이 지원되지 않는 브라우저 입니다. 크롬, 사파리, 삼성인터넷 등 다른 브라우저에서 시도해보세요.",
                            country
                          )
                        );
                      } else {
                        googleLogin();
                      }
                    }}
                    //disabled={renderProps.disabled}
                    style={{
                      cursor: "pointer",
                      height: 60,
                      backgroundColor: PALETTE.COLOR_WHITE,
                      borderRadius: 50,
                      width: "100%",
                      flexDirection: "row",
                      alignContent: "center",
                      alignItems: "center",
                      padding: 10,
                      justifyContent: "space-between",
                      marginBottom: 40,
                    }}
                  >
                    <img
                      src={"/assets/auth/google.png"}
                      style={{
                        width: 30,
                        height: 30,
                      }}
                    ></img>
                    <div
                      style={{
                        color: "black",
                      }}
                    >
                      {Translator("구글로 시작하기", country)}
                    </div>
                    <div></div>
                  </div>
                  <Link
                    href={"/emailLogin"}
                    style={{
                      textDecoration: "none",
                      height: 60,
                      backgroundColor: PALETTE.COLOR_NAVY, // PALETTE.COLOR_WHITE,
                      borderRadius: 50,
                      width: "100%",
                      flexDirection: "row",
                      alignContent: "center",
                      alignItems: "center",
                      padding: 10,
                      justifyContent: "space-between",
                    }}
                  >
                    <img
                      src="/assets/auth/email.png"
                      style={{
                        width: 30,
                        height: 30,
                      }}
                    ></img>
                    <div
                      style={{
                        fontWeight: "bold",
                        color: "white",
                      }}
                    >
                      {Translator("이메일로 시작하기", country)}
                    </div>
                    <div></div>
                  </Link>
                </div>
                <div
                  style={{
                    height: "40%",
                    paddingBottom: "10%",
                    width: "100%",
                    justifyContent: "center",
                    alignContent: "center",
                    alignItems: "center",
                  }}
                >
                  <div
                    style={{
                      flexDirection: "row",
                      alignContent: "center",
                      alignItems: "center",
                      marginBottom: 5,
                    }}
                  >
                    <Link
                      href={"/privacy"}
                      style={{
                        textDecoration: "none",
                        marginRight: 10,
                        color: "white",
                        fontWeight: "bold",
                      }}
                    >
                      {Translator("개인정보 처리방침", country)}
                    </Link>
                    <Link
                      href={"/terms-of-service"}
                      style={{
                        textDecoration: "none",
                        color: "white",
                        fontWeight: "bold",
                      }}
                    >
                      {Translator("이용약관", country)}
                    </Link>
                  </div>
                  <div
                    style={{
                      color: "white",
                      fontSize: 12,
                    }}
                  >
                    {Translator(
                      "회원가입시 엔픽스튜디오의 개인정보 처리방침과",
                      country
                    )}
                  </div>
                  <div
                    style={{
                      color: "white",
                      fontSize: 12,
                    }}
                  >
                    {Translator("이용약관에 동의하는걸로 간주합니다.", country)}
                  </div>
                  <div
                    style={{
                      marginTop: 50,
                      fontSize: 12,
                      marginBottom: 5,
                      color: "white",
                    }}
                  >
                    주식회사 엔픽컴퍼니
                  </div>
                  <div
                    style={{
                      fontSize: 12,
                      marginBottom: 5,
                      color: "white",
                    }}
                  >
                    대표자: 박세정
                  </div>
                  <div
                    style={{
                      fontSize: 12,
                      marginBottom: 5,
                      color: "white",
                    }}
                  >
                    유선 전화번호: 031-842-5253
                  </div>
                  <div
                    style={{
                      fontSize: 12,
                      marginBottom: 5,
                      color: "white",
                    }}
                  >
                    사업자등록번호: 894-86-02799
                  </div>
                  <div
                    style={{
                      fontSize: 12,
                      marginBottom: 5,
                      color: "white",
                    }}
                  >
                    주소: 서울특별시 마포구 마포대로 49, 502호(도화동, 성우빌딩)
                  </div>
                  <div
                    style={{
                      fontSize: 12,
                      marginBottom: 5,
                      fontWeight: "bold",
                      color: "white",
                    }}
                  >
                    © (주)엔픽컴퍼니 All rights reserved
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
  ) : (
    <div></div>
  );
}
